window._ = require('lodash');

window.axios = require('axios');


window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');

window.moment = require('moment');

// Bootratp
require('bootstrap');

// Colorpicker
require('../../node_modules/bootstrap-colorpicker/dist/js/bootstrap-colorpicker');

// Datatables
require('../../node_modules/datatables.net');
require('../../node_modules/datatables.net-bs4');

// Date range picker
require('../../node_modules/daterangepicker');


// iban
top.IBAN = require('./iban.js');


