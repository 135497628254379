/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require('./bootstrap');

// Ajax token
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

/**
 * Common function
 */
MyApp = {};
MyApp.Common = {
    showError: function(title, message) {
        $('#modal-error .modal-title').html(title);
        $('#modal-error .modal-body p').html(message);
        $('#modal-error').modal();
    },

    // Show general appModal
    appModal: function(options, appModal = 'appModal') {
        options = $.extend(true, {
            title: 'Título',
            content: 'Contenido',
            textOk: '<i class="fas fa-check"></i> Ok',
            textCancel: '<i class="fas fa-times"></i> Cancelar',
            showOk: true,
            showFooter: true,
            Ok: function() {},
            Cancel: function() {}
        }, options);

        // Set content
        $('#' + appModal + ' .modal-title').html(options.title);
        $('#' + appModal + ' .modal-body').html(options.content);
        if (!options.showOk) {
            $('#' + appModal + ' .modal-confirm').remove();
        } else {
            $('#' + appModal + ' .modal-confirm').html(options.textOk)
        };
        $('#' + appModal + ' .modal-cancel').html(options.textCancel);
        if (!options.showFooter) {
            $('#' + appModal + ' .modal-footer').hide();
        } else {
            $('#' + appModal + ' .modal-footer').show();
            $('#' + appModal + ' .modal-confirm').click(function() {
                $('#' + appModal + ' .modal-body form').submit();
                options.Ok()
            });

            $('#' + appModal + ' .modal-cancel').click(function() {
                options.Cancel();
            });
        }

        // Show modal
        $('#' + appModal).modal();
    },

    // Create slug
    createSlug: function(str) {
        return str.toString().toLowerCase()
            .replace(new RegExp('á', 'g'), 'a') // Replace ticked letters
            .replace(new RegExp('é', 'g'), 'e') // Replace ticked letters
            .replace(new RegExp('í', 'g'), 'i') // Replace ticked letters
            .replace(new RegExp('ó', 'g'), 'o') // Replace ticked letters
            .replace(new RegExp('ú', 'g'), 'u') // Replace ticked letters
            .replace(new RegExp('ñ', 'g'), 'n') // Replace ñ
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(/[^\w\-]+/g, '') // Remove all non-word chars
            .replace(/\-\-+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            //.replace(/-+$/, '');                // Trim - from end of text
    },
    checkShopGroupName: function(name,groupId){
        var params = {
            name: name,
            groupId: groupId,
        };

        $.get({
            type: "post",
            url: '/ajax/checkshopgroupsname',
            data: params,
            success: function(response) {
              
                if (response.ok) {
                    $('#nameusemessage').hide();
                    $('button').prop('disabled', false);
                } else {
                    $('#nameusemessage').show();
                    $('button').prop('disabled', true);
                }
            }
        });
        
    },
    //Comprobar si la tienda tiene otra empresa con ese slug
    checkShopGroupSlug: function(slug, groupId) {
        var params = {
            slug: slug,
            groupId: groupId,
        };

        $.get({
            type: "post",
            url: '/ajax/checkshopgroupslug',
            data: params,
            success: function(response) {
               
                if (response.ok) {
                    $('#sluginusemessage').hide();
                    $('button').prop('disabled', false);
                } else {
                    $('#sluginusemessage').show();
                    $('button').prop('disabled', true);
                }
            }
        });
    },

    // Check if a slug is used by another admon
    checkSlug: function(slug, licencia) {
        var params = {
            slug: slug,
            licencia: licencia,
        };

        $.get({
            type: "post",
            url: '/ajax/checkslug',
            data: params,
            success: function(response) {
    
                if (response.ok) {
                    $('#sluginusemessage').hide();
                    $('button').prop('disabled', false);
                } else {
                    $('#sluginusemessage').show();
                    $('button').prop('disabled', true);
                }
            }
        });
    },

    // Check if a licence is assigned to another admon
    checkLicencia: function(licencia, userId) {
        var params = {
            licencia: licencia,
            userId: userId,
        };

        $.get({
            type: "post",
            url: '/ajax/checklicencia',
            data: params,
            success: function(response) {
                if (response.ok) {
                    $('#licenciaerrormessage').hide();
                } else {
                    $('#licenciaerrormessage').html(response.message);
                    $('#licenciaerrormessage').show();
                }

                if (response.allowRegister) {
                    $('button').prop('disabled', false);
                } else {
                    $('button').prop('disabled', true);
                }
            }
        });
    },

    //Comprobar si la tienda tiene otra empresa con esa clave de acceso
    checkShopGroupSecret: function(secret, groupId) {
        var params = {
            secret: secret,
            groupId: groupId,
        };

        $.get({
            type: "post",
            url: '/ajax/checkshopgroupsecret',
            data: params,
            success: function(response) {
                if (response.ok) {
                    $('#secretinusemessage').html('');
                    $('#secretinusemessage').hide();
                    $('button').prop('disabled', false);
                    $('input[type=submit]').prop('disabled', false);
                } else {
                    $('#secretinusemessage').html(response.content);
                    $('#secretinusemessage').show();
                    $('button').prop('disabled', true);
                    $('input[type=submit]').prop('disabled', true);
                }
            }
        });
    },



    //Importar al formulario los datos de un punto de venta que ya existe (sin usuario asociado)
    importAdmonDataToForm: function(licencia) {
        var params = {
            licencia: licencia,
        };

        $.get({
            type: "post",
            url: '/ajax/importadmondatatoform',
            data: params,
            success: function(response) {
                $('label[for="name"]').parent().removeClass('is-empty');
                $('#name').val(response.name);

                $('label[for="slug"]').parent().removeClass('is-empty');
                $('#slug').val(response.slug);

                $('label[for="telephone"]').parent().removeClass('is-empty');
                $('#telephone').val(response.telephone);

                $('label[for="address"]').parent().removeClass('is-empty');
                $('#address').val(response.address);

                $('label[for="provinces_id"]').parent().removeClass('is-empty');
                $('#provinces_id').val(response.provinces_id);

                $('label[for="town"]').parent().removeClass('is-empty');
                $('#town').val(response.town);

                $('label[for="postalcode"]').parent().removeClass('is-empty');
                $('#postalcode').val(response.postalcode);
            }
        });
    },

    // Show modal to change order status
    drawChangeOrderStatusModal: function(orderid) {
        var params = {
            orderid: orderid
        };

        $.get({
            type: "post",
            url: '/ajax/drawchangeorderstatusmodal',
            data: params,
            success: function(response) {
                $('#drawchangeorderstatusmodal').modal();
                $('#drawchangeorderstatusmodal').html(response.content);
                if ($('#qrcode-input')) {
                    $('#qrcode-input').focus();
                }
                $(document).on('click', '.orderstatusitem', function() {
                    var status = $(this).attr('status');
                    MyApp.Common.drawChangeOrderStatusConfirmModal(orderid, status);
                });
            }
        });
    },

    // Show modal to change order status
    drawChangeOrderStatusConfirmModal: function(orderid, status) {
        var params = {
            orderid: orderid,
            status: status,
        };

        $.get({
            type: "post",
            url: '/ajax/drawchangeorderstatusconfirmmodal',
            data: params,
            success: function(response) {
                $('#drawchangeorderstatusconfirmmodal').modal();
                $('#drawchangeorderstatusconfirmmodal').html(response.content);

            }
        });
    },

    //Añadir dinámicamente el contenido del modal para editar un movimiento
    drawEditMovementModal: function(movementid) {
        var params = {
            movementid: movementid
        };

        $.get({
            type: "post",
            url: '/ajax/draweditmovementmodal',
            data: params,
            success: function(response) {
                $('#draweditmovementmodal').modal();
                $('#draweditmovementmodal').html(response.content);
            }
        });
    },

    //Borrar un décimo o participación por ajax
    deleteNumber: function(numbersId) {
        var params = {
            numbersId: numbersId
        };

        $.get({
            url: '/ajax/decimo/' + numbersId,
            type: 'DELETE',
            data: params,
            success: function(data) {
                $('#row-' + data.id).remove();
            },
            error: function(xhr, ajaxOptions, thrownError) {
                MyApp.Common.showError('Se ha producido un error', 'No ha sido posible eliminar el décimo');
            }
        });
    },

    //Re-enviar email de confirmación al comprador
    resendOrderConfirmationEmail: function(orderid) {
        var params = {
            orderid: orderid
        };

        $.get({
            type: "post",
            url: '/ajax/order/' + orderid + '/send-confirmation',
            data: params,
            success: function(response) {
                if (response.message != "") {
                    MyApp.Common.appModal({
                        title: 'Confirmación del envío',
                        content: response.message,
                        textCancel: '<i class="fas fa-times"></i> Cerrar',
                        showOk: false,
                    });
                }
            }
        });
    },

    drawConfirmDeleteCompanyModal: function(groupid) {
        var params = {
            groupid: groupid
        };

        $.get({
            type: "post",
            url: '/ajax/drawconfirmdeletecompanymodal',
            data: params,
            success: function(response) {
                $('#confirmdeletecompanymodal').modal();
                $('#confirmdeletecompanymodalbody').html(response.content);
            }
        });
    },

    //Añadir dinámicamente el contenido del modal para editar un movimiento
    processBalanceMovement: function(movementid, sendEmail) {
        var params = {
            movementid: movementid,
            sendEmail: sendEmail
        };

        $.get({
            type: "post",
            url: '/ajax/processbalancemovement',
            data: params,
            success: function(response) {
                $('#processed_' + movementid).removeClass('notprocessed');
                $('#processed_' + movementid).addClass('processed');
                $('#processed_' + movementid).attr('data-original-title', 'Transferencia procesada');

                // Remove option from new balance movement form
                $('#askedbyuser option[value="' + movementid + '"]').remove();
                if (response.error) {
                    // console.log('response :'+response.error);
                    MyApp.Common.showError('Se ha producido un error', 'Fallo al procesar: transacción no procesada '+ '<br> -'+response.error);
                }
            },
            error: function(xhr, ajaxOptions, thrownError) {
                console.log(movementid);
            }
        });
    },

    checkgacodeformat: function(ga_code) {
        var params = {
            ga_code: ga_code
        };

        $.get({
            type: "post",
            url: '/ajax/checkgacodeformat',
            data: params,
            success: function(data) {
                if (data.ok) {
                    $('#submitgacode').attr('disabled', false);
                    $('#ga_code_error').addClass('hidden');
                } else {
                    $('#submitgacode').attr('disabled', true);
                    $('#ga_code_error').removeClass('hidden');
                }
            },
            error: function(xhr, ajaxOptions, thrownError) {
                console.log(xhr);
            }
        });
    },

    //Comprobar el formato del DNI
    checkIdentificationFormat: function(identification, errormessageid, submitid) {
        var params = {
            identification: identification,
        };

        $.get({
            type: "post",
            url: '/ajax/checkidentificationformat',
            data: params,
            success: function(response) {
                if (response.ok) {
                    $('#' + errormessageid).hide();

                    $('#' + submitid).attr('disabled', false);
                    $('#finishButton').attr('disabled', false);
                    //$('#finishButton').on('click', function(){ $('#finishForm').submit(); })
                    $('#finishButton').on('click', function() { $('#buy_button').click(); })
                } else {
                    $('#' + errormessageid).html(response.message);
                    $('#' + errormessageid).show();

                    $('#' + submitid).attr('disabled', true);
                    $('#finishButton').attr('disabled', true);
                    $('#finishButton').on('click', function() {})
                }
            }
        });
    }

}

//Cambiar la visibilidad de un número o peña
$(document).on('click', '.change-visible-number', function(e) {
    event.preventDefault();
    var numbersId = $(this).attr('numbersId');
    var visibleLR = $(this).attr('visibleLR');
    var visibleShop = $(this).attr('visibleShop');
    var inputData = {};
    var iconId = '';
    var linkId = '';
    var attr = '';

    if (visibleLR != '') {
        inputData = {
            id: numbersId,
            visible_lr: visibleLR,
        }
        iconId = '#icon-visible-lr-';
        linkId = '#link-visible-lr-';
        attr = 'visibleLR';
    } else if (visibleShop != '') {
        inputData = {
            id: numbersId,
            visible_shop: visibleShop,
        }
        iconId = '#icon-visible-shop-';
        linkId = '#link-visible-shop-';
        attr = 'visibleShop';
    }

    $.ajax({
        url: '/ajax/mi-decimo-cambiar-visiblidad',
        type: 'post',
        data: inputData,
        success: function(data) {
            //Change icon
            if (data.newVisible == '0') {
                $(iconId + data.numbersId).removeClass('fa-times').addClass('fa-check');
            } else {
                $(iconId + data.numbersId).removeClass('fa-check').addClass('fa-times');
            }

            //Change visibleLR or visibleShop attr
            $(linkId + data.numbersId).attr(attr, data.newVisible);

            //Change link text
            $(linkId + data.numbersId).text(data.newLinkText);
        },
        error: function(xhr, ajaxOptions, thrownError) {
            MyApp.Common.showError('Se ha producido un error', 'No ha sido posible modificar el décimo');
        }
    });
});

$(document).on('click', '.edit-user-delete', function(e) {
    event.preventDefault();
    var id = this.getAttribute("user_id");
    $.ajax({
        url: '/ajax/deleteUser',
        type: 'post',
        data: {
            id: id
        },
        success: function(data) {
            window.location.href = "/admin/users";
        },
        error: function(xhr, ajaxOptions, thrownError) {
            MyApp.Common.showError('Se ha producido un error', 'No ha sido posible Eliminar al usuario');
        }
    });
});